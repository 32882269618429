import { FormSize } from "../../../utils/constants";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { FormElements } from "../../../utils/constants";
import MultiTextInputComponent from "../../../components/ui/inputComponents/MultiTextInputComponent";
import MultiSelectInputComponent from "../../../components/ui/inputComponents/MultiSelectInputComponent";
import SelectInputComponent from "../../../components/ui/inputComponents/SelectInputComponent";
import AutocompleteSelectInputComponent from "../../../components/ui/inputComponents/AutocompleteSelectInputComponent";
import TextInputComponent from "../../../components/ui/inputComponents/TextInputComponent";
import TextAreaComponent from "../../../components/ui/inputComponents/TextAreaComponent";
import DateInputComponent from "../../../components/ui/inputComponents/DateInputComponent";
import moment from "moment";
import FileInputComponent from "../../../components/ui/inputComponents/FileInputComponent";
import RichTextEditorComponent from "../../../components/ui/inputComponents/RichTextEditorComponent";
import { useSelector } from "react-redux";
import OTPInputComponent from "../../../components/ui/inputComponents/OTPInputComponent";
import AsyncMultiSelectInputComponent from "../../../components/ui/inputComponents/AsyncMultiSelectInputComponent";
import { Divider } from "@mui/material";
import CheckBoxInputComponent from "../../../components/ui/inputComponents/CheckBoxInputComponent";
import DynamicSelectInputComponent from "../../../components/ui/inputComponents/DynamicSelectInputComponent";

export default function FormBody({ formik, formSetupData }) {
  let formWidth = formSetupData.width;
  let formElements = formSetupData.formElements;
  const dynamicOption = useSelector((state) => state.form.dynamicOption);

  let textEditorProps = null;

  if (formSetupData?.textEditor) {
    let id = formSetupData.textEditor.id;
    textEditorProps = {
      id: id,
      label: formSetupData.textEditor.label,
      textEditorType: formSetupData.textEditor.textEditorType,
      error: formik.errors[id],
      touched: formik.touched[id],
      value: formik.values[id],
      setFieldValue: formik.setFieldValue,
      setFieldTouched: formik.setFieldTouched,
    };
  }

  return (
    <DialogContent className="custom-scroll-bar" sx={{ px: 3.5, pt: 3, pb: 4 }}>
      <Grid container spacing={4}>
        {formElements.map((data, index) => {
          if (data.type === FormElements.BREAK_LINE) {
            return (
              <Grid item xs={12} key={index} sx={{ mt: 1 }}>
                <Divider sx={{ borderColor: "#ded8d8" }} />
              </Grid>
            );
          } else {
            return (
              <FormElement
                key={index}
                formData={data}
                formWidth={formWidth}
                formik={formik}
                dynamicOption={dynamicOption}
              />
            );
          }
        })}
        {formSetupData?.textEditor && (
          <Grid item xs={12}>
            <RichTextEditorComponent {...textEditorProps} />
          </Grid>
        )}
      </Grid>
    </DialogContent>
  );
}

function FormElement({ formData, formWidth, formik, dynamicOption }) {
  let inputType = formData.type;

  let isFullWidth = formData?.fullWidth;

  let inputProps = {
    id: formData.id,
    label: formData.label,
    error: formik.errors[formData.id],
    value: formik.values[formData.id],
    inputType: formData.inputType,
    touched: formik.touched[formData.id],
    placeholder: formData.placeholderText || null,
    disabled: formData?.disabled ?? false,
    optionFetchingFunction: formData?.optionFetchingFunction ?? null,
    setFieldValue: formik.setFieldValue,
    setFieldTouched: formik.setFieldTouched,
    handleChange: formik.handleChange,
    handleBlur: formik.handleBlur,
    options: formData?.dynamicOption
      ? dynamicOption[formData.id]
      : formData?.options ?? null,
    optionIsString: formData?.optionIsString ?? true,
    isDisabled: formData?.isDiabled ?? false,
    accept: formData?.accept ?? null,
    minDate: formData?.minIDReference
      ? new moment(formik.values[formData.minIDReference])
      : new moment(),
    timeLimit: formData?.timeLimit ?? null,
    resendOTP: formData?.resendOTP ?? null,
    multipleValues: formData?.multipleValues ?? true,
    teamsInOptions: formData?.teamsInOptions ?? false,
    equalityMethod: formData?.equalityMethod ?? null,
  };

  let content = null;

  if (formData?.renderLogic && !formData.renderLogic(formik)) {
    return content;
  }

  switch (inputType) {
    case FormElements.MULTI_INPUT_FORM_ELEMENT: {
      content = <MultiTextInputComponent {...inputProps} />;
      break;
    }
    case FormElements.ASYNC_MULTI_SELECT_ELEMENT: {
      content = <AsyncMultiSelectInputComponent {...inputProps} />;
      break;
    }
    case FormElements.MULTI_SELECT_FORM_ELEMENT: {
      content = <MultiSelectInputComponent {...inputProps} />;
      break;
    }
    case FormElements.SELECT_FORM_ELEMENT: {
      content = <SelectInputComponent {...inputProps} />;
      break;
    }
    case FormElements.AUTOCOMPLETE_SELECT_FORM_ELEMENT: {
      content = <AutocompleteSelectInputComponent {...inputProps} />;
      break;
    }
    case FormElements.DYNAMIC_SELECT_FORM_ELEMENT: {
      content = <DynamicSelectInputComponent {...inputProps} />;
      break;
    }
    case FormElements.TEXT_FIELD_ELEMENT: {
      content = <TextInputComponent {...inputProps} />;
      break;
    }
    case FormElements.TEXT_AREA_ELEMENT: {
      content = <TextAreaComponent {...inputProps} />;
      break;
    }
    case FormElements.DATE_ELEMENT: {
      content = <DateInputComponent {...inputProps} />;
      break;
    }
    case FormElements.FILE_UPLOAD_ELEMENT: {
      content = (
        <FileInputComponent
          {...inputProps}
          setFieldError={formik.setFieldError}
          setFieldTouched={formik.setFieldTouched}
        />
      );
      break;
    }
    case FormElements.OTP_INPUT_ELEMENT: {
      content = <OTPInputComponent {...inputProps} />;
      break;
    }
    case FormElements.CHEKBOX_ELEMENT: {
      content = <CheckBoxInputComponent {...inputProps} />;
      break;
    }
    default: {
      content = null;
    }
  }

  return (
    <Grid
      item
      {...(isFullWidth ? { sm: 12 } : calculateFormElementWidths(formWidth))}
    >
      {content}
    </Grid>
  );
}

function calculateFormElementWidths(parentWidth) {
  const getGridItemWidth = (breakpoint) => {
    switch (breakpoint) {
      case "xl":
        return parentWidth === FormSize.EXTRA_LARGE
          ? 4
          : parentWidth === FormSize.LARGE || parentWidth === FormSize.MEDIUM
          ? 6
          : 12;
      case "lg":
        return parentWidth === FormSize.EXTRA_LARGE ||
          parentWidth === FormSize.LARGE ||
          parentWidth === FormSize.MEDIUM
          ? 6
          : 12;
      case "md":
        return parentWidth === FormSize.EXTRA_LARGE ||
          parentWidth === FormSize.LARGE ||
          parentWidth === FormSize.MEDIUM
          ? 6
          : 12;
      case "sm":
        return 12;
      case "xs":
        return 12;
      default:
        return 12;
    }
  };

  return {
    xl: getGridItemWidth("xl"),
    lg: getGridItemWidth("lg"),
    md: getGridItemWidth("md"),
    sm: getGridItemWidth("sm"),
    xs: getGridItemWidth("xs"),
  };
}
