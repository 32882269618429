import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";

export default async function getUsers() {
  try {
    let req = await Api.get(URL.ApplicationManagement.GetAllMembers);

    let activeUsers = req.data?.filter((user) => user.is_active === true);

    activeUsers =
      activeUsers?.map((user) => ({
        id: user.id,
        email: user.email,
        label: user.email,
      })) ?? [];

    return activeUsers;
  } catch (error) {
    Notification.error(NotificationLabels.ERROR_IN_FETCHING_USERS);
    throw new Error(error);
  }
}
