import downloadSelfSignedCertService from "../../services/keysAndCertificates/downloadSelfSignedCertificate";
import {
  Labels,
  ModalLabels,
  ModalType,
  NotificationLabels,
} from "../../utils/constants";
import { Box, Typography, Stack, Divider } from "@mui/material";
import downloadBackup from "../../services/backup/downloadBackup";
import restoreBackup from "../../services/backup/restoreBackup";
import archiveEmailTemplate from "../../services/email/archiveEmailTemplate";
import restoreEmailTemplate from "../../services/email/restoreEmailTemplate";
import archiveEmailConfig from "../../services/email/archiveEmailConfig";
import restoreEmailConfig from "../../services/email/restoreEmailConfig";
import restoreIdentity from "../../services/identityStore/restoreIdentity";
import archiveIdentity from "../../services/identityStore/archiveIdentity";
import updateKeyAndCertStatusService from "../../services/keysAndCertificates/updateKeyAndCertStatus";
import editApplicationStatusService from "../../services/applicationManagement/editApplicationStatusService";
import { store } from "../../lib/redux/store";
import { setDeletedEnvironmentIDS } from "../applicationManagement/applicationManagementSlice";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Notification from "../../utils/notificationConfiguration";
import deleteUserService from "../../services/user/deleteUserService";
import manageUserLockStatusService from "../../services/user/manageUserLockStatusService";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Grid from "@mui/material/Grid";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { deleteSigningProjectService } from "../../services/signingProjects/signingProjectsService";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useDispatch } from "react-redux";
import { closeModal } from "./modalSlice";
import { useNavigate } from "react-router-dom";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectInputComponent from "../../components/ui/inputComponents/SelectInputComponent";
import { initializeForm } from "../forms/formSlice";
import { FormType } from "../../utils/constants";
import deleteCertificate from "../../services/sslManagement/deleteCertificate";
import upadteModalFlag from "../../services/sslManagement/updateModalFlag";
import renewSSLCertificate from "../../services/sslManagement/renewSSLCertificate";
import deleteEnvironment from "../../services/applicationManagement/deleteEnvironment";
import deleteTeam from "../../services/applicationManagement/deleteTeam";
import { initializeModal } from "./modalSlice";

const modalConfig = {
  [ModalType.ACTIVATE_KEY]: {
    title: "Activate Key",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to activate this key?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: updateKeyAndCertStatusService,
  },
  [ModalType.DEACTIVATE_KEY]: {
    title: "Deactivate Key",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to deactivate this key?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: updateKeyAndCertStatusService,
  },
  [ModalType.DOWNLOAD_SELF_SIGNED_CERT]: {
    title: "Download Certificate",
    hideFooter: true,
    customComponent: true,
    body: function Component({ data }) {
      const [selectedFormat, setSelectedFormat] = useState("pem");

      const dispatch = useDispatch();

      function handleModalClose() {
        dispatch(closeModal());
      }

      function handleModalSubmit() {
        downloadSelfSignedCertService(data.certificateName, selectedFormat);
      }

      const handleChange = (event) => {
        setSelectedFormat(event.target.value);
      };

      return (
        <>
          <DialogContent sx={{ width: 1 }}>
            <Typography variant="subtitle1">
              Download the certificate you have generated.
            </Typography>

            <Box
              sx={{
                mt: 2,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                File Format
              </Typography>

              <FormControl>
                <RadioGroup
                  row
                  value={selectedFormat}
                  onChange={handleChange}
                  sx={{ gap: 1 }}
                >
                  <FormControlLabel
                    value="pem"
                    control={<Radio />}
                    label="PEM"
                  />
                  <FormControlLabel
                    value="crt"
                    control={<Radio />}
                    label="CRT"
                  />
                  <FormControlLabel
                    value="cer"
                    control={<Radio />}
                    label="CER"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </DialogContent>

          <DialogActions sx={{ display: "flex", gap: 2, pb: 2, px: 3 }}>
            <Button
              sx={{ borderRadius: "5px" }}
              variant="outlined"
              onClick={() => handleModalClose()}
            >
              {ModalLabels.CANCEL}
            </Button>

            <Button
              sx={{ borderRadius: "5px" }}
              variant="contained"
              onClick={() => {
                handleModalClose();
                handleModalSubmit();
              }}
              autoFocus
            >
              {ModalLabels.DOWNLOAD}
            </Button>
          </DialogActions>
        </>
      );
    },
  },
  [ModalType.DOWNLOAD_BACKUP]: {
    title: "Download Backup",
    body: (
      <Typography variant="subtitle1">
        Your Backup has been generated!
      </Typography>
    ),
    buttonLabelForCancel: ModalLabels.CANCEL,
    buttonLabelForSubmit: ModalLabels.DOWNLOAD,
    onModalSubmit: downloadBackup,
  },
  [ModalType.RESTORE_BACKUP]: {
    title: "Restore Backup",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to proceed with restoring this backup?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: restoreBackup,
  },
  [ModalType.ARCHIVE_EMAIL_TEMPLATE]: {
    title: "Archive Email Template",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to archive this email template?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: archiveEmailTemplate,
  },
  [ModalType.RESTORE_EMAIL_TEMPLATE]: {
    title: "Restore Email Template",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to restore this email template?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: restoreEmailTemplate,
  },
  [ModalType.ARCHIVE_EMAIL_CONFIG]: {
    title: "Archive Email Configuration",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to archive this email configuration?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: archiveEmailConfig,
  },
  [ModalType.RESTORE_EMAIL_CONFIG]: {
    title: "Restore Email Configuration",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to restore this email configuration?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: restoreEmailConfig,
  },
  [ModalType.RESTORE_IDENTITY]: {
    title: "Restore Identity",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to restore this identity?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: restoreIdentity,
  },
  [ModalType.ARCHIVE_IDENTITY]: {
    title: "Archive Identity",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to archive this identity?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: archiveIdentity,
  },
  [ModalType.ACTIVATE_APPLICATION]: {
    title: "Activate Application",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to activate this application?
      </Typography>
    ),

    buttonLabelForCancel: ModalLabels.NO,
    buttonLabelForSubmit: ModalLabels.YES,
    onModalSubmit: editApplicationStatusService,
  },
  [ModalType.DEACTIVATE_APPLICATION]: {
    title: "Deactivate Application",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to deactivate this application?
      </Typography>
    ),

    buttonLabelForCancel: ModalLabels.NO,
    buttonLabelForSubmit: ModalLabels.YES,
    onModalSubmit: editApplicationStatusService,
  },
  [ModalType.TOKEN_GENERATION]: {
    title: "Generated Token",
    customComponent: true,
    hideFooter: true,
    body: function Component({ data }) {
      return (
        <DialogContent sx={{ width: 1 }}>
          <Stack direction="row" sx={{ alignItems: "center", gap: 2, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontSize: "0.9rem" }}>
              Your exclusive Authorization Token has been successfully
              generated. Here's your API key
            </Typography>

            <IconButton
              size="medium"
              onClick={() => {
                Notification.success(NotificationLabels.TOKEN_COPIED);
                navigator.clipboard.writeText(data.token);
              }}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Stack>

          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "0.9rem",
              p: 1,
              border: "1px solid #d4cdcd",
              wordWrap: "break-word",
              borderRadius: "5px",
            }}
          >
            {data.token}
          </Typography>
        </DialogContent>
      );
    },
  },
  [ModalType.P12_GENERATION]: {
    title: "Generated Authentication Certificate",
    customComponent: true,
    hideFooter: true,
    body: function Component({ data }) {
      return (
        <DialogContent sx={{ width: 1 }}>
          <Stack direction="row" sx={{ alignItems: "center", gap: 2, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontSize: "0.9rem" }}>
              Please retain this password for certificate setup. Without it,
              you'll be required to create a new authentication certificate.
            </Typography>

            <IconButton
              size="medium"
              onClick={() => {
                Notification.success(NotificationLabels.PASSWORD_COPIED);
                navigator.clipboard.writeText(data.password);
              }}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Stack>

          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "0.9rem",
              p: 1,
              border: "1px solid #d4cdcd",
              wordWrap: "break-word",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            {data.password}
          </Typography>
        </DialogContent>
      );
    },
  },
  [ModalType.DELETE_USER]: {
    title: "Delete User",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to delete this user?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: deleteUserService,
  },
  [ModalType.LOCK_USER]: {
    title: "Lock User",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to lock this user?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: manageUserLockStatusService,
  },
  [ModalType.UNLOCK_USER]: {
    title: "Unlock User",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to unlock this user?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: manageUserLockStatusService,
  },
  [ModalType.USER_ROLES_INFO]: {
    width: "lg",
    title: "User Roles & Permissions",
    customComponent: true,
    hideFooter: true,
    body: function Component({ _ }) {
      return (
        <DialogContent sx={{ width: 1 }}>
          <Stack direction="row" sx={{ alignItems: "center", gap: 2 }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                fontWeight: 700,
                width: "fit-content",
                flexShrink: 0,
              }}
            >
              Roles :
            </Typography>

            <Stack
              direction="row"
              sx={{
                width: 1,
                gap: 3,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {store.getState().auth.userRoles.map((role) => (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "0.9rem",
                    px: "10px",
                    py: "2px",
                    backgroundColor: "#efebeb",
                    borderRadius: "5px",
                    fontWeight: 700,
                  }}
                >
                  {role.label}
                </Typography>
              ))}
            </Stack>
          </Stack>

          <Divider sx={{ borderColor: "#eaeaea", mt: 3 }} />

          <Box sx={{ width: 1, mt: 3, mb: 2 }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                fontWeight: 700,
                mb: 3,
              }}
            >
              Permissions
            </Typography>

            <Grid container spacing={4} sx={{ height: 1, overflowY: "auto" }}>
              {Object.entries(store.getState().auth.userPermissions)
                .filter(([pid, _]) => pid > 10)
                .map(([pid, permission]) => (
                  <Grid
                    key={pid}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {permission.status === true ? (
                      <CheckCircleOutlinedIcon
                        sx={{ fontSize: 20, color: "success.dark" }}
                      />
                    ) : (
                      <CancelOutlinedIcon
                        sx={{ fontSize: 20, color: "error.dark" }}
                      />
                    )}

                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 600, fontSize: "0.9rem" }}
                    >
                      {permission.label}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </DialogContent>
      );
    },
  },
  [ModalType.MANAGE_TEAM]: {
    title: "Manage Team",
    customComponent: true,
    hideFooter: true,
    body: function Component({ data }) {
      let dispatch = useDispatch();

      const formik = useFormik({
        initialValues: {
          team: null,
        },
        validationSchema: Yup.object().shape({
          team: Yup.object().required("Please select a team"),
        }),
        onSubmit: ({ team }) => {
          dispatch(closeModal());
          dispatch(
            initializeForm({
              formType: FormType.EDIT_TEAM,
              initialValue: {
                name: team.name,
                members: team.members,
              },
              serviceData: {
                teamID: team.id,
                certificateIDs: team.certificateIDs,
              },
              dynamicOption: {
                members:
                  store.getState().applicationManagement.formState.members,
              },
            })
          );
        },
      });

      function onTeamDelete(id) {
        dispatch(
          initializeModal({
            modalType: ModalType.TEAM_REMOVAL_CONFIRMATION_MODAL,
            modalData: { teamID: id },
          })
        );
      }

      return (
        <DialogContent sx={{ width: 1 }}>
          <Stack sx={{ flexDirection: "column", gap: 2 }}>
            <Box sx={{ width: 1 }}>
              <SelectInputComponent
                label="Team"
                error={formik.errors["team"]}
                touched={formik.touched["team"]}
                placeholder={"Select Team"}
                id="team"
                handleBlur={formik.handleBlur}
                value={formik.values["team"]}
                options={data.teams}
                optionIsString={false}
                setFieldValue={formik.setFieldValue}
              />
            </Box>
            <Stack
              direction={"row"}
              sx={{ ml: "auto", width: "fit-content", gap: 2 }}
            >
              <Button
                onClick={async () => {
                  const errors = await formik.validateForm();
                  formik.setTouched({ team: true });

                  if (!errors.team && formik.values.team) {
                    dispatch(closeModal());

                    let teamID = formik.values.team.id;
                    onTeamDelete(teamID);
                  }
                }}
                sx={{ mt: 1, borderRadius: "5px" }}
                size="small"
                variant="outlined"
              >
                Delete Team
              </Button>
              <Button
                onClick={() => {
                  formik.handleSubmit();
                }}
                sx={{ mt: 1, borderRadius: "5px" }}
                size="small"
                variant="contained"
                color="primary"
              >
                Edit Team
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      );
    },
  },
  [ModalType.LICENSE]: {
    width: "md",
    title: "License Information",
    customComponent: true,
    hideFooter: true,
    body: function Component({ _ }) {
      let navigate = useNavigate();
      let dispatch = useDispatch();
      let licenseInfo = store.getState().license.licenseInfo;
      let isUserAuthorized = checkUserPermission(Permissions.ActivateLicense);
      return (
        <DialogContent sx={{ width: 1 }}>
          <Stack sx={{ flexDirection: "column", gap: 2 }}>
            <Stack
              sx={{
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
                variant="h6"
              >
                License Name :
              </Typography>
              <Typography variant="subtitle1">
                {licenseInfo.licenseName}
              </Typography>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
                variant="h6"
              >
                Activated On :
              </Typography>
              <Typography variant="subtitle1">
                {licenseInfo.activatedOn}
              </Typography>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
                variant="h6"
              >
                Expires On :
              </Typography>
              <Typography variant="subtitle1">
                {licenseInfo.expiresOn}
              </Typography>
            </Stack>
            {isUserAuthorized && (
              <Button
                onClick={() => {
                  navigate("/license?action=renew");
                  dispatch(closeModal());
                }}
                sx={{ mt: 1, borderRadius: "5px" }}
                size="small"
                variant="contained"
                color="primary"
              >
                Renew License
              </Button>
            )}
          </Stack>
        </DialogContent>
      );
    },
  },
  [ModalType.DELETE_SIGNING_PROJECT]: {
    title: "Delete Project",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to delete this Signing Project?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: deleteSigningProjectService,
  },
  [ModalType.RENEW_SSL_CERTIFICATE]: {
    title: "Renew Existing Certificate",
    warningIcon: true,
    body: (
      <Typography variant="subtitle1">
        You're about to renew the existing SSL/TLS Certificate, which was
        provided by Encryption Consulting LLC. Are you sure about it?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: renewSSLCertificate,
  },
  [ModalType.DELETE_SSL_CERTIFICATE]: {
    title: "Delete SSL/TLS Certificate",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to delete this entry?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: deleteCertificate,
  },
  [ModalType.SSL_FLAG_MODAL]: {
    title: "SSL/TLS Certificate",
    customComponent: true,
    hideFooter: true,
    body: function Component({ _ }) {
      let navigate = useNavigate();

      const dispatch = useDispatch();

      function handleModalClose() {
        dispatch(closeModal());
      }

      return (
        <>
          <DialogContent sx={{ width: 1 }}>
            <Typography variant="subtitle1">
              You're using Encryption Consulting's issued SSL/TLS certificate.
              We suggest you upload your PKI issued certificate.
            </Typography>
          </DialogContent>

          <DialogActions sx={{ display: "flex", gap: 2, pb: 2, px: 3 }}>
            <Button
              sx={{ borderRadius: "5px" }}
              variant="outlined"
              onClick={() => {
                handleModalClose();
                upadteModalFlag();
              }}
            >
              {Labels.CANCEL}
            </Button>

            <Button
              sx={{ borderRadius: "5px" }}
              variant="contained"
              onClick={() => {
                handleModalClose();
                upadteModalFlag();
                navigate("/system-setup/ssl-tls-management");
              }}
            >
              {Labels.UPLOAD}
            </Button>
          </DialogActions>
        </>
      );
    },
  },
  [ModalType.SBOM_MODAL]: {
    title: "SBOM Scan Result",
    customComponent: true,
    hideFooter: true,
    body: function Component({ data }) {
      function renderStatusCell(status) {
        let cellText = "";

        if (status === "success") {
          cellText = "Success";
        } else if (status === "warning") {
          cellText = "Warning";
        }

        function getBGColorForCell(cellText) {
          switch (cellText) {
            case "Success": {
              return "success.main";
            }
            case "Warning": {
              return "error.main";
            }
            default: {
              return "";
            }
          }
        }

        return (
          <Box
            sx={{
              width: "100px",
              textAlign: "center",
              paddingBlock: 0.7,
              color: "primary.main",
              borderRadius: "5px",
              fontSize: "0.9rem",
              backgroundColor: getBGColorForCell(cellText),
            }}
          >
            {cellText}
          </Box>
        );
      }

      return (
        <DialogContent sx={{ width: 1 }}>
          <Stack direction="row" sx={{ alignItems: "center", gap: 2, mb: 2 }}>
            <Typography variant="h3" sx={{ fontSize: "1rem", fontWeight: 700 }}>
              Status :
            </Typography>

            {renderStatusCell(data.status)}
          </Stack>

          <Stack direction="row" sx={{ alignItems: "center", gap: 2, mb: 2 }}>
            <Typography variant="h3" sx={{ fontSize: "1rem", fontWeight: 700 }}>
              Message :
            </Typography>

            <Typography variant="subtitle1" sx={{ fontSize: "0.9rem" }}>
              {data.message}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
              mb: 1,
            }}
          >
            <Typography variant="h3" sx={{ fontSize: "1rem", fontWeight: 700 }}>
              Log
            </Typography>

            <IconButton
              size="medium"
              onClick={() => {
                Notification.success("Log Copied");
                navigator.clipboard.writeText(data.log);
              }}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Stack>

          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "0.9rem",
              p: 1,
              border: "1px solid #d4cdcd",
              wordWrap: "break-word",
              borderRadius: "5px",
            }}
          >
            {data.log}
          </Typography>
        </DialogContent>
      );
    },
  },
  [ModalType.ENVIRONMENT_REMOVAL_CONFIRMATION_MODAL]: {
    title: "Delete Environment",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to delete this environment?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: deleteEnvironment,
  },
  [ModalType.TEAM_REMOVAL_CONFIRMATION_MODAL]: {
    title: "Delete Team",
    body: (
      <Typography variant="subtitle1">
        Are you sure you want to delete this team?
      </Typography>
    ),
    buttonLabelForCancel: Labels.NO,
    buttonLabelForSubmit: Labels.YES,
    onModalSubmit: deleteTeam,
  },
};

export default modalConfig;
