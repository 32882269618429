function hexToHsl(hex) {
  let r = parseInt(hex.substring(1, 3), 16) / 255;
  let g = parseInt(hex.substring(3, 5), 16) / 255;
  let b = parseInt(hex.substring(5, 7), 16) / 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
}

export function generateHslShadesFromHex(hexColors, numShades) {
  let hslShades = [];

  hexColors.forEach((hex) => {
    const baseHsl = hexToHsl(hex);

    // Generate shades by adjusting both lightness and saturation
    for (let i = 0; i < numShades; i++) {
      // Dynamically adjust lightness and saturation
      const lightness = baseHsl.l - i * (baseHsl.l / (numShades * 0.7)) + 15; // Ensure shades don't get too dark
      const saturation = baseHsl.s - i * (baseHsl.s / (numShades * 0.5)) + 10; // Adjust saturation slightly

      const adjustedLightness = Math.min(Math.max(lightness, 25), 75); // Keep lightness in a visible range
      const adjustedSaturation = Math.min(Math.max(saturation, 40), 90); // Ensure saturation stays vivid

      hslShades.push(
        `hsl(${baseHsl.h}, ${adjustedSaturation}%, ${adjustedLightness}%)`
      );
    }
  });

  return hslShades;
}
