// React
import { useRef, useEffect, useState } from "react";

// Redux
import { resetSignatureCount, selectSignatureCount } from "./dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import getSignatureCountService from "../../services/dashboard/getSignatureCountService";

// MUI
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Chart JS
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

// Constants
import { Status } from "../../utils/constants";
import { Skeleton } from "@mui/material";
import { useErrorBoundary } from "react-error-boundary";

import { ChartColors } from "../../utils/constants";
import { generateHslShadesFromHex } from "../../utils/helper";

Chart.register(CategoryScale);

const filterTypes = [
  {
    type: "Default",
    label: "Total",
  },
  // {
  //   type: "Application",
  //   label: "Application",
  // },
  {
    type: "Certificate_name",
    label: "Certificate Name",
  },
  // {
  //   type: "Environment_name",
  //   label: "Environment Name",
  // },
];

function LoadingSkeleton() {
  return (
    <Box sx={{ width: 1, height: 1 }}>
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          minHeight: "330px",
          height: "100%",
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

// A select form input for filter type
function FilterTypeDropdown({
  data,
  filterType,
  changeFilterType,
  changeFilterOption,
}) {
  function handleFilterTypeChange(event) {
    let type = event.target.value;
    changeFilterType(type);

    if (type !== "Default") {
      changeFilterOption(Object.keys(data[type])[0]);
    }
  }

  return (
    <Box>
      <FormControl
        size="small"
        sx={{
          minWidth: "170px",
        }}
      >
        <Select
          sx={{
            fontSize: "10px",
            borderRadius: "5px",
            backgroundColor: "tertiary.main",
            ".MuiSelect-select": { padding: "0.35em 0.75em" },
          }}
          id="filter-type"
          value={filterType}
          onChange={handleFilterTypeChange}
        >
          {filterTypes.map((filter, index) => (
            <MenuItem key={filter.label + index} value={filter.type}>
              <Typography variant="subtitle2">{filter.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

// A select form input for filter option based on filter type
function FilterOptionDropdown({
  data,
  filterType,
  filterOption,
  changeFilterOption,
}) {
  if (filterType === "Default") {
    return null;
  }

  let options = Object.keys(data[filterType]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Box>
      <FormControl
        size="small"
        sx={{
          minWidth: "200px",
        }}
      >
        <Select
          sx={{
            fontSize: "10px",
            borderRadius: "5px",
            backgroundColor: "tertiary.main",
            ".MuiSelect-select": { padding: "0.35em 0.75em" },
          }}
          id="filter-type"
          value={filterOption}
          onChange={(event) => {
            changeFilterOption(event.target.value);
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={option + index} value={option}>
              <Typography variant="subtitle2">{option}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

// A Chart component to dispay the counts
function CountChart({ data, filterType, filterOption }) {
  let counts =
    filterType === "Default"
      ? data[filterType]
      : data[filterType][filterOption];

  const shades = generateHslShadesFromHex(ChartColors, 6);

  const chartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Count",
        backgroundColor: shades,
        barPercentage: 0.2,
        borderRadius: 5,
        borderWidth: 1,
        data: counts,
      },
    ],
  };

  const chartOption = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        px: 2,
        py: 1,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Bar data={chartData} options={chartOption} />
    </Box>
  );
}

// Main component
export default function SignatureCount() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { data, status, error } = useSelector(selectSignatureCount);

  // State for filter type
  const [currentFilterType, setCurrentFilterType] = useState(
    filterTypes[0].type
  );
  // State for filter option
  const [currentFilterOption, setCurrentFilterOption] = useState(null);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getSignatureCountService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTotalCount() {
    if (currentFilterType === "Default") {
      return data["Default"]?.reduce((total = 0, curr) => total + curr) ?? 0;
    } else {
      return (
        data[currentFilterType][currentFilterOption]?.reduce(
          (total = 0, curr) => total + curr
        ) ?? 0
      );
    }
  }

  if (status === Status.Failed) {
    showBoundary(error);
  }

  // If the api request is going on then this loader will be returned and rendered
  if (data == null && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  // Header Component for Signature Count
  function Header() {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 2, py: 1 }}
      >
        <Typography variant="h6" sx={{ fontSize: "1.2rem", lineHeight: 1 }}>
          Signature Count - {getTotalCount()}
        </Typography>

        <Stack direction="row" gap={2} alignItems={"center"}>
          <FilterTypeDropdown
            data={data}
            filterType={currentFilterType}
            changeFilterType={setCurrentFilterType}
            changeFilterOption={setCurrentFilterOption}
          />

          <FilterOptionDropdown
            data={data}
            filterType={currentFilterType}
            filterOption={currentFilterOption}
            changeFilterOption={setCurrentFilterOption}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Paper
      elevation={0}
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: "5px",
        minHeight: "330px",
      }}
    >
      <Header />

      <Divider />

      <CountChart
        data={data}
        filterType={currentFilterType}
        filterOption={currentFilterOption}
      />
    </Paper>
  );
}
