import { Box, Paper, TextField, Autocomplete } from "@mui/material";
import InputLabel from "./InputLabel";

export default function AutocompleteSelectInputComponent({
  label,
  error,
  touched,
  placeholder,
  id,
  handleBlur,
  value: selectedValue,
  options,
  optionIsString,
  isDisabled = false,
  setFieldValue,
  size = "large",
}) {
  return (
    <Box sx={{ position: "relative", width: 1 }}>
      <InputLabel label={label} error={error} touched={touched} size={size} />
      <Paper
        elevation={0}
        sx={{
          px: 2,
          py: 1.5,
          ...(size === "small" && { px: 1.5, py: 0.75 }),
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
        }}
      >
        <Autocomplete
          disabled={isDisabled}
          options={options}
          value={selectedValue || null}
          onChange={(_, value) => {
            setFieldValue(id, value);
          }}
          onBlur={handleBlur}
          getOptionLabel={(option) =>
            optionIsString ? option : option.label || ""
          }
          isOptionEqualToValue={(option, value) =>
            optionIsString ? option === value : option?.id === value?.id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={placeholder}
              error={!!(error && touched)}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                sx: { ...(size === "small" && { fontSize: "0.85rem" }) },
              }}
            />
          )}
        />
      </Paper>
    </Box>
  );
}
