import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableOptionIcon from "../../icons/TableOptionIcon";
import { useRef, useState } from "react";
import { Typography } from "@mui/material";
import DownloadIcon from "../../icons/DownloadIcon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function CustomToolBar({
  handlePDFExport,
  handleCSVExport,
  handleSysLogExport,
  optionConfig,
  toggleShowAllRows,
  showAllRows,
}) {
  function generateMenuOptions() {
    let options = [];

    if (optionConfig.includePDFExport) {
      options.push({
        id: 1,
        label: "PDF",
        handler: () => handlePDFExport(),
      });
    }

    if (optionConfig.includeCSVExport) {
      options.push({ id: 2, label: "CSV", handler: () => handleCSVExport() });
    }

    if (optionConfig.includeSYSLogExport) {
      options.push({
        id: 3,
        label: "Syslog",
        handler: () => handleSysLogExport(),
      });
    }

    if (optionConfig.includeShowMaxRows) {
      options.push({
        id: 4,
        label: "Max Rows",
        handler: () => toggleShowAllRows((prev) => !prev),
      });
    }

    return options;
  }

  let menuOptions = generateMenuOptions();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuAnchorRef = useRef(null);

  return (
    <>
      <IconButton
        sx={{
          position: "absolute",
          top: "30px",
          right: "20px",
          background: "white !important",
        }}
        ref={menuAnchorRef}
        aria-label="table-option"
        onClick={() => {
          setIsMenuOpen(true);
        }}
      >
        <TableOptionIcon width="30px" fill="#1B2C58" />
      </IconButton>
      <Menu
        id="table-menu"
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onClose={() => {
          setIsMenuOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "5px",
          },
        }}
      >
        {menuOptions.map((option) => {
          if (option.id === 4) {
            return (
              <MenuItem
                key={option.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  px: 2,
                }}
              >
                <FormControlLabel
                  onChange={() => option.handler()}
                  control={<Switch size="small" checked={showAllRows} />}
                  label={
                    <Typography sx={{ fontSize: "1rem" }} variant="subtitle1">
                      {option.label}
                    </Typography>
                  }
                />
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={option.id}
              onClick={() => {
                setIsMenuOpen(false);
                option.handler();
              }}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                px: 2,
              }}
            >
              <DownloadIcon width="17px" fill="primary.main" />
              <Typography sx={{ fontSize: "1rem", pr: 3 }} variant="subtitle1">
                {option.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
