import { Box, Paper, TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import InputLabel from "./InputLabel";
import { CircularProgress } from "@mui/material"; // Import CircularProgress

export default function DynamicSelectInputComponent({
  label,
  error,
  touched,
  placeholder,
  id,
  handleBlur,
  value: selectedValue,
  optionFetchingFunction,
  optionIsString,
  isDisabled = false,
  setFieldValue,
  multipleValues,
  equalityMethod = "id",
  teamsInOptions,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    async function fetchOptions() {
      try {
        const fetchedOptions = await optionFetchingFunction();
        setOptions(fetchedOptions);
      } catch (error) {
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }

    fetchOptions();
  }, [optionFetchingFunction]);

  const filteredOptions = options.filter((option) => {
    const label = optionIsString ? option : option?.label || "";
    return label.toLowerCase().includes(inputValue.toLowerCase());
  });

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (_, value) => {
    if (teamsInOptions && multipleValues) {
      let users = [];

      value.forEach((data) => {
        if (data?.isTeam) {
          // Add only members that are not already in the selected values
          data?.members.forEach((member) => {
            if (!users.some((user) => user.email === member.email)) {
              users.push(member);
            }
          });
        } else {
          // For individual users, check if they are already in the selected users
          if (!users.some((user) => user.email === data.email)) {
            users.push(data);
          }
        }
      });

      // Set the field value with the updated list of users (no duplicates)
      setFieldValue(id, users);
    } else {
      // For non-multiple values, just set the value as it is
      setFieldValue(id, value);
    }
  };

  const isOptionEqualToValue = (option, value) => {
    switch (equalityMethod) {
      case "label":
        return optionIsString
          ? option === value
          : option?.label === value?.label;
      case "id":
      default:
        return optionIsString ? option === value : option?.id === value?.id;
    }
  };

  return (
    <Box sx={{ position: "relative", width: 1 }}>
      <InputLabel
        label={label}
        error={error}
        touched={touched}
        size={"large"}
      />
      <Paper
        elevation={0}
        sx={{
          px: 2,
          py: 1.5,
          // ...(size === "small" && { px: 1.5, py: 0.75 }),
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
        }}
      >
        <Autocomplete
          size="small"
          limitTags={2}
          multiple={multipleValues}
          disabled={isDisabled || loading}
          options={filteredOptions}
          value={selectedValue || (multipleValues ? [] : null)}
          onChange={handleChange}
          onBlur={handleBlur}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          getOptionLabel={(option) =>
            optionIsString ? option : option?.label || ""
          }
          loading={loading}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={loading ? "Fetching options..." : placeholder}
              error={!!(error && touched)}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                style: {
                  fontWeight: loading ? "bold" : "normal",
                  color: loading ? "#000" : "rgba(0, 0, 0, 0.6)",
                  opacity: 1,
                },
              }}
            />
          )}
        />
      </Paper>
    </Box>
  );
}
