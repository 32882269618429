import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import { closeForm } from "../../features/forms/formSlice";
import getAllTeamService from "./getAllTeamService";

export default async function deleteTeam(data) {
  try {
    store.dispatch(showLoader());

    await Api.delete(URL.ApplicationManagement.ManageTeam + `${data.teamID}/`);

    store.dispatch(getAllTeamService());

    store.dispatch(closeForm());

    Notification.success("Environment deleted successfully");
  } catch (error) {
    Notification.error("Failed to delete environment");
  } finally {
    store.dispatch(closeLoader());
  }
}
