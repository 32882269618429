import { Stack, Button } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState, useEffect, useRef } from "react";
import { initializeForm } from "../../../features/forms/formSlice";
import { useDispatch } from "react-redux";
import { FormType } from "../../../utils/constants";
import SearchUser from "../../../features/user/SearchUser";
import RoleFilter from "../../../features/user/RoleFilter";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import checkUserPermission from "../../../utils/checkUserPermission";
import { Permissions } from "../../../utils/enums";

const tabLists = {
  "/system-setup/user/management": 0,
  "/system-setup/user/permissions": 1,
};

function UserOptionGroup({ dispatch }) {
  let options = getUserOptions();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    if (options[selectedIndex] === "Register User") {
      dispatch(
        initializeForm({
          formType: FormType.REGISTER_USER,
        })
      );
    } else if (options[selectedIndex] === "Generate API Key") {
      dispatch(
        initializeForm({
          formType: FormType.GENERATE_API_KEY,
        })
      );
    } else {
      dispatch(
        initializeForm({
          formType: FormType.GENERATE_AUTHENTICATION_CERTIFICATE,
        })
      );
    }
  };

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function getUserOptions() {
    const options = [];
    if (checkUserPermission(Permissions.RegisterUser)) {
      options.push("Register User");
    }

    if (checkUserPermission(Permissions.CreateAPIKey)) {
      options.push("Generate API Key");
    }

    if (checkUserPermission(Permissions.CreateSSLCertificate)) {
      options.push("Generate Authentication Certificate");
    }

    return options;
  }

  if (options.length === 0) {
    return null;
  }

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        sx={{
          "& > .MuiButtonBase-root": {
            p: 0.5,
            fontSize: "0.9rem",

            "&:nth-child(1)": {
              px: 1.5,
              borderBottomLeftRadius: "5px",
              borderTopLeftRadius: "5px",
            },

            "&:nth-child(2)": {
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
            },
          },
        }}
      >
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
        <Button size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper
              sx={{
                borderRadius: "5px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      sx={{ fontSize: "0.9rem" }}
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

function Header({ pathname, navigate, dispatch }) {
  const [activeTab, setActiveTab] = useState(
    (() => {
      const matchingPath = Object.keys(tabLists).find((key) =>
        pathname.startsWith(key)
      );
      return matchingPath ? tabLists[matchingPath] : null;
    })()
  );

  useEffect(() => {
    const matchingPath = Object.keys(tabLists).find((key) =>
      pathname.startsWith(key)
    );

    setActiveTab(() => (matchingPath ? tabLists[matchingPath] : null));
  }, [pathname]);

  const handleChange = (_, newValue) => {
    if (newValue !== null) {
      setActiveTab(newValue);
      navigate(Object.keys(tabLists).find((key) => tabLists[key] === newValue));
    }
  };

  return (
    <Stack direction={"column"} sx={{ gap: 2 }}>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ToggleButtonGroup
          size="small"
          value={activeTab}
          exclusive
          onChange={handleChange}
          sx={{
            ".MuiButtonBase-root": {
              borderRadius: "5px",
              padding: "8px 20px",
              fontWeight: 600,

              "&:first-child": {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },

              "&:last-child": {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
            },
            ".Mui-selected": {
              backgroundColor: "#1B2C58 !important",
              color: "#FFFFFF !important",
              "&:hover": {
                backgroundColor: "#1B2C58 !important",
                color: "#FFFFFF !important",
                opacity: 0.9,
              },
            },
          }}
        >
          {checkUserPermission(Permissions.ViewSystemSetupUserManagement) && (
            <ToggleButton value={0}>Management</ToggleButton>
          )}
          {checkUserPermission(Permissions.ViewSystemSetupUserPermissions) && (
            <ToggleButton value={1}>Permissions</ToggleButton>
          )}
        </ToggleButtonGroup>

        {activeTab === 0 && pathname === "/system-setup/user/management" && (
          <SearchUser />
        )}
      </Stack>

      {activeTab === 0 && pathname === "/system-setup/user/management" && (
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <RoleFilter />
          <UserOptionGroup dispatch={dispatch} />
        </Stack>
      )}
    </Stack>
  );
}

export default function SystemSetupUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Stack sx={{ width: 1, height: 1 }}>
      <Header pathname={pathname} navigate={navigate} dispatch={dispatch} />
      <Outlet />
    </Stack>
  );
}
