import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";

export default async function getUsersAndTeams() {
  try {
    let userRequest = Api.get(URL.ApplicationManagement.GetAllMembers);
    let teamRequest = Api.get(URL.ApplicationManagement.GetAllTeams);

    const requests = await Promise.all([userRequest, teamRequest]);

    let result = [];

    requests.forEach((req, index) => {
      if (index === 0) {
        let activeUsers = req.data?.filter((user) => user.is_active === true);

        activeUsers =
          activeUsers?.map((user) => ({
            id: user.id,
            email: user.email,
            label: user.email,
          })) ?? [];

        if (activeUsers.length > 0) {
          result.push(...activeUsers);
        }
      } else {
        let teams =
          req.data?.map((data) => ({
            isTeam: true,
            id: data.id,
            label: data?.team_name ?? null,
            members:
              data?.members?.map((member) => ({
                id: member.id,
                email: member.username,
                label: member.username,
              })) ?? [],
          })) ?? [];

        if (teams.length > 0) {
          result.push(...teams);
        }
      }
    });

    console.log(result);

    return result;
  } catch (error) {
    Notification.error(NotificationLabels.ERROR_IN_FETCHING_USERS);
    throw new Error(error);
  }
}
