import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import { closeForm } from "../../features/forms/formSlice";
import getAllEnvironmentService from "./getAllEnvironmentService";

export default async function deleteEnvironment(formData) {
  try {
    store.dispatch(showLoader());

    await Api.delete(
      URL.ApplicationManagement.DeleteEnvironment +
        `${formData.environment?.id}/`
    );

    store.dispatch(getAllEnvironmentService());

    store.dispatch(closeForm());

    Notification.success("Environment deleted successfully");
  } catch (error) {
    Notification.error("Failed to delete environment");
  } finally {
    store.dispatch(closeLoader());
  }
}
