// React
import { useRef, useEffect, useState } from "react";

// MUI
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// React Router
import { Link, useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectApplicationTableData } from "./applicationManagementSlice";
import getApplicationTableDataService from "../../services/applicationManagement/getApplicationTableDataService";

// Constants
import { Status, Labels } from "../../utils/constants";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
// Custom Components
import CustomTable from "../../components/ui/table/CustomTable";
import { Skeleton } from "@mui/material";
import { useErrorBoundary } from "react-error-boundary";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import Typography from "@mui/material/Typography";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        mt: 3,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function ApplicationTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const abortControllerRef = useRef(null);

  const { tableData, status, error } = useSelector(selectApplicationTableData);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getApplicationTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return (
    <TableComponent
      tableData={tableData}
      navigate={navigate}
      dispatch={dispatch}
    />
  );
}

function TableComponent({ tableData, navigate, dispatch }) {
  const [actionMenuState, setActionMenuState] = useState({
    anchorEl: null,
    selectedRowData: null,
  });

  const rowData = tableData;

  const columnData = [
    {
      field: "applicationName",
      headerName: Labels.APPLICATION_NAME,
      width: 250,
      filterable: false,
      renderCell: (params) => renderApplicationName(params),
    },
    {
      field: "firstApplicationOwnerName",
      headerName: Labels.APPLICATION_OWNERS,
      width: 300,
      valueFormatter: (params) => {
        if (params.value?.length === 0) {
          return "-";
        }
        return params.value[0].label;
      },
    },
    {
      field: "status",
      headerName: Labels.STATUS,
      width: 140,
      renderCell: (params) => renderStatusCell(params.value),
    },
    {
      field: "applicationDescription",
      headerName: Labels.APPLICATION_DESCRIPTION,
      flex: 1,
      minWidth: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "action",
      headerName: Labels.ACTION,
      width: 140,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    },
  ];

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function renderApplicationName(params) {
    if (params.value) {
      return (
        <Box
          sx={{
            mr: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#1e90ff",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail/${params.row.applicationID}`}
          >
            {params.value}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function handleEditApplicationClicked(applicationID) {
    setActionMenuState({ anchorEl: null, selectedRowData: null });
    navigate(`${applicationID}`);
  }

  function handleEditStatusClicked() {
    let { selectedRowData } = actionMenuState;
    if (selectedRowData?.status === Labels.ACTIVE) {
      dispatch(
        initializeModal({
          modalType: ModalType.DEACTIVATE_APPLICATION,
          modalData: {
            applicationID: selectedRowData.applicationID,
            currentStatus: selectedRowData.status,
          },
        })
      );
    } else {
      dispatch(
        initializeModal({
          modalType: ModalType.ACTIVATE_APPLICATION,
          modalData: {
            applicationID: selectedRowData.applicationID,
            currentStatus: selectedRowData.status,
          },
        })
      );
    }
    setActionMenuState({ anchorEl: null, selectedRowData: null });
  }

  function ActionMenu() {
    let { selectedRowData, anchorEl } = actionMenuState;

    return (
      <Menu
        sx={{
          "& .MuiPaper-root .MuiList-root": {
            minWidth: "150px",
            py: 0,
            "& .MuiButtonBase-root": {
              py: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "tertiary.light",
              fontSize: "0.9rem",
              fontWeight: 500,
              "&:hover": {
                bgcolor: "tertiary.main",
              },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontSize: "0.9rem",
                fontWeight: 500,
              },
            },
          },
        }}
        id="action-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setActionMenuState({ anchorEl: null, selectedRowData: null });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {selectedRowData?.status === Labels.ACTIVE && (
          <MenuItem
            onClick={() =>
              handleEditApplicationClicked(selectedRowData.applicationID)
            }
          >
            {Labels.EDIT}
          </MenuItem>
        )}
        <MenuItem onClick={() => handleEditStatusClicked()}>
          {selectedRowData?.status === Labels.ACTIVE
            ? "Deactivate"
            : "Activate"}
        </MenuItem>
      </Menu>
    );
  }

  function renderActionCell(rowData) {
    if (checkUserPermission(Permissions.ManageApplication)) {
      return (
        <Box
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={(event) =>
              setActionMenuState({
                anchorEl: event.target,
                selectedRowData: rowData,
              })
            }
            size="small"
            color="primary"
            aria-label="more actions"
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
          -
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <CustomTable
        showTableOptions={true}
        optionConfig={{ fileName: "Applications" }}
        rowData={rowData}
        columnData={columnData}
      />
      <ActionMenu />
    </>
  );
}
